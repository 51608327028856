import { environment } from '../../environments/environment';
import { DBNames } from './dbnames';


const apiUrl = environment.apiUrl + '/api/';
export const endpoints = {
  auth: apiUrl + 'auth/token',
  createPacientes:  environment.apiUrl + '/create_customer',
  pacientes:  apiUrl + DBNames.patient,
  tags:  apiUrl + DBNames.tags,
  procedures: apiUrl + DBNames.procedures,
  getPacientes:  environment.apiUrl + '/get_patients',
  getPatientAppointments:  environment.apiUrl + '/get_customers_appointments',
  citas: apiUrl + DBNames.apointment,
  practicant: apiUrl + DBNames.practitioner,
  setCita: environment.apiUrl + '/settle_appointment',
  updateStatusCita: environment.apiUrl + '/update_appointment_status',
  updateScheduleCita: environment.apiUrl + '/update_appointment_schedule',
  getSchedule: environment.apiUrl + '/get_schedule',
  appoinments: environment.apiUrl + '/get_medical_appointments',
  documents: apiUrl + DBNames.documents,
  history: apiUrl + DBNames.history,
  createHistory: environment.apiUrl + '/create_history',
  deleteAppointment: environment.apiUrl + '/delete_appointment',
  validateTechnician: environment.apiUrl + '/validate_technician_code'
} ;
