export const DBNames = {
    // instanceName: 'wcc',
    instanceName: 'wcc',
    // instanceName: '10lame',
    patient: 'medical.patient',
    practitioner: 'medical.practitioner',
    apointment: 'medical.appointment',
    procedures: 'medical.procedure',
    tags:  'medical.procedure.tag',
    documents: 'medical.contract',
    history: 'medical.history',
};
