import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AuthenticationService } from './services/authentication.service';
import { RouterMenuLink } from './models/router-menu.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  user:string;
  public appPages: Array<RouterMenuLink> = [
    {
      title: 'Citas',
      url: '/home',
      icon: 'calendar'
    },
    
  ];

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navctrl: NavController,
    private autentication: AuthenticationService,
    private router: Router
  ) {
    this.initializeApp();
  }

  initializeApp() {
    if(!this.autentication.isAutenticated()){
      this.navctrl.navigateRoot("login")
    }
    
    const callback = ()=>{
      this.user = this.autentication.getUser();
      const profile =  this.autentication.getProfile()
      if(profile.is_technician){
        return
      }
      if((profile.is_admin || profile.secretaria ) && this.appPages.length < 3){
        this.appPages.push(
          {
            title: 'Agendar Cita',
            url: '/set-appointment',
            icon: 'paper'
          },
          {
            title: 'Pacientes',
            url: '/patients',
            icon: 'people'
          })
      }
    }

    window.addEventListener('login', callback)
    window.addEventListener('refresh', callback)

    this.user = this.user || this.autentication.getUser();

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  isLoginRoute(): boolean {
    return this.router.url === '/login';
  }
  
  logout(){
    this.autentication.logout()
    this.navctrl.navigateRoot("login")
  }
}
