import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../models/user';
import { endpoints } from '../constants/endpoints';
import { PracticantService } from './practicant.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    constructor(private http: HttpClient, private practicantService: PracticantService) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    isAutenticated(){
        let userData = JSON.parse(localStorage.getItem('currentUser'));
        try {
            if(userData.access_token){
                return true
            }
            return false
        } catch (e) {
            console.log(e)
            return false
        }
        
    }

    isConsulta(){
        let userData = JSON.parse(localStorage.getItem('currentUser'));
        try {
            return userData.consulta
        } catch (error) {
            return ""
        }
    }

    getPracticantIId(){
        let userData = JSON.parse(localStorage.getItem('currentUser'));
        try {
            return userData.practicant
        } catch (error) {
            return ""
        }
    }

    getUser(){
        let userData = JSON.parse(localStorage.getItem('currentUser'));
        try {
            return userData.name
        } catch (error) {
            return ""
        }
    }

    getProfile() {
        let userData = JSON.parse(localStorage.getItem('currentUser')); 
        return {
            consulta: userData.consulta,
            is_technician: userData.is_technician,
            secretaria: userData.secretaria,
            is_admin: userData.is_admin
        }
    }

    login(login: string, password: string, db: string) {

        let url = endpoints.auth;
        return this.http.get<any>(url, {params: {login, password, db}})
             .pipe(map((res: any) => {
                if (res && res.access_token) {
                    // store user details in local storage to keep user logged in
                    res.username = login;
                    const loginEvent = new Event('login');
                    localStorage.setItem('currentUser', JSON.stringify(res));
                    window.dispatchEvent(loginEvent)
                    this.currentUserSubject.next(res);
                }

                return res;
            }));
    }

    logout() {
        // remove user data from local storage for log out
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }
}