import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterMenuLink } from 'src/app/models/router-menu.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  @Input() menus: Array<RouterMenuLink>;
  @Input() isLogin: boolean;
  @Output() action = new EventEmitter<void>();
  @Input() userName: string;

  logout(){
    this.action.emit();
  }
}
