import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { endpoints } from '../constants/endpoints';

@Injectable({
  providedIn: 'root'
})
export class PracticantService {
  fiels = `[('id'),('name'),('user_id')]`;
  constructor(private http: HttpClient) { }

  getAll() {
    let domain = `[('practitioner_type','=',"internal"),('consulta','=', True)]`
    return this.http.get<any[]>(`${endpoints.practicant}?fields=${this.fiels}&domain=${domain}`, {headers: this.getHeaders()});
  }

  private getHeaders(){
    const headers = new HttpHeaders({
      "charset":'utf-8',
      "Content-Type": 'application/x-www-form-urlencoded'
    });

    return headers
  }
}
